@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;600;700&display=swap");

p {
  margin: 0;
  /* font-family: "Work Sans"; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
}

.li-dashboard {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* font-family: "Work Sans"; */
  font-weight: 300;
  font-size: 15px;
}

.thumbs {
  padding: 0;
}

.thumb {
  height: 30 !important;
  width: 30 !important;
}

.thumbs-wrapper {
  margin: 0 !important;
}

.link-under-thumbnail:hover {
  text-decoration: underline;
}
